// assets
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ThunderstormOutlinedIcon from '@mui/icons-material/ThunderstormOutlined';


// constant
const icons = { SpeedOutlinedIcon, ThunderstormOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.SpeedOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'weather',
            title:'weather_forecast',
            type: 'item',
            url: '/weather-forecast',
            icon: icons.ThunderstormOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
