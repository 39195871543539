import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';
import organization from './organization';
import instrument from './instrument';
import admin from './admin';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //
const ManagerItems = {
    // items: [dashboard, pages, utilities, other] /* Original Template
    items: [dashboard, organization, instrument]
};

const OperatorItems = {
    // items: [dashboard, pages, utilities, other] /* Original Template
    items: [dashboard, instrument]
};

const ZeutecAdminItems = {
    items: [dashboard, admin, other]
};

export { ManagerItems, OperatorItems, ZeutecAdminItems };