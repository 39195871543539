import React, { useEffect, useState, useCallback } from "react";
import i18react from '../../../../i18react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EnglishFlag from '../../../../assets/images/icons/english.png';
import GermanyFlag from '../../../../assets/images/icons/germany.png';
import SpanishFlag from '../../../../assets/images/icons/spanish.png';
import FrenchFlag from '../../../../assets/images/icons/french.png';
import ItalianFlag from '../../../../assets/images/icons/italian.png';

const languageOptions = [
  { value: 'en', icon: EnglishFlag, label: 'English' },
  { value: 'de', icon: GermanyFlag, label: 'Deutsch' },
  { value: 'es', icon: SpanishFlag, label: 'Spanish' },
  { value: 'fr', icon: FrenchFlag, label: 'French' },
  { value: 'it', icon: ItalianFlag, label: 'Italian' }
];

const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

const Header = () => {

  const selectedLangFromCookie = getCookie("selectedLanguage");
  const selectedLang = selectedLangFromCookie || 'en';

  const [lang, setLang] = useState(selectedLang);

  useEffect(() => {
    if (selectedLangFromCookie) {
      i18react.changeLanguage(selectedLangFromCookie);
    } else {
      i18react.changeLanguage("en");
    }

    const i18nextChangeLanguage = (lng) => {
      setLang(lng);
      document.cookie = `selectedLanguage=${lng}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    };

    i18react.on("languageChanged", i18nextChangeLanguage);

    return () => {
      i18react.off("languageChanged", i18nextChangeLanguage);
    };
  }, [selectedLangFromCookie]);

  const handleLanguageChange = useCallback((event) => {
    const selectedLang = event.target.value;
    i18react.changeLanguage(selectedLang);
  }, []);

  return (
    <FormControl sx={{ border: 'none', background: 'transparent', minWidth: 0, '&:before': { border: 'none' } }}>
      <Select
        value={lang}
        onChange={handleLanguageChange}
        IconComponent={ExpandMoreIcon}
        sx={{ border: 'none', background: 'transparent' }}
      >
        {languageOptions.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ border: 'none', background: 'transparent' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={option.icon}
                alt={option.value}
                style={{ width: '30px', marginRight: '10px' }}
              />
              {option.label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Header;