import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const SignIn = Loadable(lazy(() => import('../views/pages/authentication/Login')));
// const SignUp = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const SignUp = Loadable(lazy(() => import('views/pages/authentication/registration')));
const Forgot = Loadable(lazy(() => import('views/pages/authentication/forgotPass')));
const Reset = Loadable(lazy(() => import('views/pages/authentication/resetpass')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/sign-in',
            element: <SignIn />
        },
        {
            path: '/sign-up',
            element: <SignUp />
        },
        {
            path: '/confirm/:confirmationCode',
            element: <SignIn />
        },
        {
            path: '/forgotpassword',
            element: <Forgot />
        },
        {
            path: '/resetpassword/:confirmationCode',
            element: <Reset />
        },
        {
            path: '/acceptOrg/:confirmationCode',
            element: <SignIn />
        }
    ]
};

export default AuthenticationRoutes;
