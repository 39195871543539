import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import lEn from './locales/en.json'
import lDe from './locales/de.json'
import lEs from './locales/es.json'
import lFr from './locales/fr.json'
import lIt from './locales/it.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
  en: {
    translation: lEn
  },
  de: {
    translation: lDe
  },
  es: {
    translation: lEs
  },
  fr: {
    translation: lFr
  },
  it: {
    translation: lIt
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources,

    lng: "en",

    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;