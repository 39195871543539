// assets
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';


// constant
const icons = {
    AddHomeWorkOutlinedIcon,
    AppSettingsAltOutlinedIcon,
    PeopleOutlinedIcon,
    MonetizationOnOutlinedIcon,
    SettingsOutlinedIcon,
    MapOutlinedIcon,
    OilBarrelOutlinedIcon,
    AddchartOutlinedIcon,
    TopicOutlinedIcon
};

// const icons1 = {
//     IconSettings
// };

// ==============================|| EXTRA organization MENU ITEMS ||============================== //

const organization = {
    id: 'organization',
    title: 'organization',
    // caption: 'organization Caption',
    type: 'group',
    children: [
        {
            id: 'organizations',
            title: 'organizations',
            type: 'item',
            icon: icons.AddHomeWorkOutlinedIcon,
            url: '/organizations'
        },
        {
            id: 'devices',
            title:'devices',
            type: 'item',
            url: '/all-devices',
            icon: icons.AppSettingsAltOutlinedIcon,
        },
        {
            id: 'members',
            title: 'members',
            type: 'item',
            icon: icons.PeopleOutlinedIcon,
            url: '/all-members'
        },
        {
            id: 'commodity',
            title: 'commodities',
            type: 'collapse',
            // url: '/commodities',
            icon: icons.OilBarrelOutlinedIcon,
            children: [
                {
                    id: 'method',
                    title: 'methods',
                    type: 'item',
                    url: '/method-manager',
                    icon: icons.TopicOutlinedIcon
                },
                {
                    id: 'calibrationsAdmin',
                    title: 'calibration',
                    type: 'item',
                    url: '/calibrations-manager',
                    icon: icons.AddchartOutlinedIcon
                },
                {
                    id: 'commodity',
                    title: 'commidity',
                    type: 'item',
                    url: '/commodities-manager',
                    icon: icons.OilBarrelOutlinedIcon
                },
            ]
        },
        {
            id: 'subscription',
            title:'subscription',
            type: 'item',
            url: '/subscriptions',
            icon: icons.MonetizationOnOutlinedIcon
        },
        {
            id: 'fencing',
            title: 'fencing',
            type: 'item',
            url: '/geoFencing',
            icon: icons.MapOutlinedIcon
        },
        {
            id: 'settings',
            title:  'settings',
            type: 'item',
            url: '/settings',
            icon: icons.SettingsOutlinedIcon
        }
    ]
};

export default organization;
