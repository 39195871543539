// material-ui
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// project imports
import NavGroup from './NavGroup';
import { ManagerItems, OperatorItems, ZeutecAdminItems } from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [role, setRole] = useState("Loading");
    useEffect(() => {
        setRole(localStorage.getItem('role'));
    }, []);

    const navManagerItems = ManagerItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const navOperatorItems = OperatorItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const navZeutecAdminItems = ZeutecAdminItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const navLoadingItems = () => {
        return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Loading
            </Typography>
        );
    }

    if (role == "Manager") {
        return <>{navManagerItems}</>;
    } else if (role == "Operator") {
        return <>{navOperatorItems}</>;
    } else if (role == "zeutecAdmin") {
        return <>{navZeutecAdminItems}</>;
    } else
        if (role == "Loading") {
            return <>{navLoadingItems}</>;

        };
}

export default MenuList;
