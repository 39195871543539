import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import HomepageRoutes from './HomepageRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = () => {
    return useRoutes([HomepageRoutes, AuthenticationRoutes, MainRoutes]);
}

export default ThemeRoutes;
